export const TOKENT_KEY_NAME = 'kes-token';
export const DEFAULT_CURRENCY = 'KES';
export const DEFAULT_TIMEZONE = 3;
export const DEFAULT_LANG = 'en';
export const AVAILABLE_LANGS = ['en'];
export const ONLY_CURRENCY: string | null = 'KES';
export const EXCLUDED_CURRENCY: string[] | null = ['FUN', 'DEMO', 'BUSD', 'TZS'];
export const AVAILABLE_THEMES = ['light-blue'];
export const DEFAULT_THEME = 'light-blue';
export const COMPANY_NAME = 'QBET';
export const SUPPORT_LINK = 'https://wa.me/254113719719';
export const SOCIAL_LINK_TG = '';
export const SOCIAL_LINK_INST = '';
export const SOCIAL_LINK_VK = '';
export const SOCIAL_LINK_FACEBOOK = '';
export const SOCIAL_LINK_TWITTER_X = '';
export const SOCIAL_LINK_TIKTOK = '';
export const LOGO_BOOMAKER_RATINGS = '';
export const ANDROID_APP = '';
export const EXCLUDE_CASINO_CATEGORY = ['gameslobby', 'roshambo', 'arcade', 'moon', 'jetx'];
export const PASSWORD_MIN_LENGTH = 4;

export const DEFAULT_LOGOTYPE = '/logotype.svg';
export const MainLogotype = {
  DARK_PURPLE: '/logotype.png',
  BLUE: '/logotype.png',
  DARK_BLUE: '/logotype.png',
  LIGHT_BLUE: '/logotype.png',
};
export const CouponLogotype = {
  DARK_PURPLE: '/logotype.png',
  BLUE: '/logotype.png',
  DARK_BLUE: '/logotype.png',
  LIGHT_BLUE: '/logotype.png',
};

export const AppFeature = {
  DIGITAIN_GAMES: false,
  BONUS_AND_PROMO: true,
  REGISTRATION_TELEGRAM: false,
  REGISTRATION_PHONE: false,
  KYC_VERIFICATION: false,
  ADAPTIVE_BANNERS: false,
  KES_HOME_PAGE: true,
  KES_PROJECT: true,
  KES_TAXES: true,
  DISABLE_SPORTBOOK: false,
  CASINO_ONLY: false,
};

if (ONLY_CURRENCY && EXCLUDED_CURRENCY) {
  if (EXCLUDED_CURRENCY.includes(ONLY_CURRENCY)) {
    throw new Error('EXCLUDED_CURRENCY contains the ONLY_CURRENCY value');
  }
}
